import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {hover} from "../../styles/globalStyle";
import Title from "../Title";
import {Img} from "../Img";
import Button from "../Button";
import reactHtmlParser from "react-html-parser";

const Mission = ({data}) => {

    const [offset, setOffset] = useState();
    useEffect(() => {
        setOffset(document.querySelector(" .container").offsetLeft);
    }, []);

    return (

        <StyledDetail className={'mission-vision pb-200'}>
            <Container>
                <Row>
                    <Col lg={6}>
                        <div className="mission-vision__content">
                            {
                                data?.list && data?.list.length>0 &&
                                data?.list.map((element,index)=>{
                                    if(index%2==0){
                                        return(
                                            <div className="mission-vision__content__upper" key={index}>
                                                {element?.title && <h3 className={'split-up'}>{reactHtmlParser(element?.title)}</h3>}
                                                {element?.description &&<p className={'split-up'}>{reactHtmlParser(element?.description)}</p>}
                                            </div>
                                        )
                                    }
                                    else {
                                        return(
                                            <div className="mission-vision__content__lower" key={index}>
                                                {element?.title && <h3 className={'split-up'}>{reactHtmlParser(element?.title)}</h3>}
                                                {element?.description &&<p className={'split-up'}>{reactHtmlParser(element?.description)}</p>}
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mission-vision__image reveal">
                            <Img  src={data?.image}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledDetail>

    );
};

const StyledDetail = styled.section`
  .mission-vision {
    &__content {
      position: relative;
      height: 100%;
      background: #2D2D2D;
      align-items: center;
      h3 {
        font-size: 24px;
        line-height: 32px;
        font-family: Kudryashev Display;
        color: #FFFEFB;
        margin-bottom: 15px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        font-family: "Suisse Int'l";
        font-weight: 400;
        color: #F9F8F5;
      }

      &__upper {
       padding: 60px 70px;
        border-bottom: 1px solid #ffffff;
        @media(min-width: 1550px){
          height: 50%;
        }

      }

      &__lower {
        padding: 60px 70px;
        //margin-left: 15px;
        @media(min-width: 1550px){
          height: 50%;
        }
      }
    }

    &__image {
      position: relative;
      padding-top: calc(570 / 570 * 100%);
      height: 100%;
    }
  }
  
  @media(max-width: 991px){
    .row{
      flex-direction: column-reverse;
    }
    .col-md-6{
      flex: 0 0 100%;
      width: 100%;
      min-width: 100%;
    }
    .mission-vision__image{
      margin-bottom: 20px;
    }
  }

  @media(max-width: 767px){
    .mission-vision__content{
      height: 100vh;
    }
    .mission-vision__content__upper{
      padding: 25px 20px;
    }
    .mission-vision__content__lower{
      padding: 25px 20px;
    }
  }

`;

export default Mission;
