import React, { useEffect } from 'react';
import gsap from 'gsap';
import styled from 'styled-components';

const SliderContainer = styled.div`
  position: relative;
  
  
  padding-top:  calc(768 /598 * 100%);
  //width: 250px;
  //height: 250px;
  overflow: hidden;
`;

const Slide = styled.div`
  position: absolute;
  inset: 0;
  overflow: hidden;
  
`;

const SlideImage = styled.img`
  position: absolute;
  width: 100%;
`;

const Button = styled.button`
  margin: 20px;
`;

const Slider = () => {
    useEffect(() => {
        const $slides = gsap.utils.toArray('.slide');
        const $slidesImage = gsap.utils.toArray('img');
        const $buttonPrev = document.getElementById('prev');
        const $buttonNext = document.getElementById('next');

        let totalSlides = $slides.length;
        let currentSlide = 0;
        const slidesWrap = gsap.utils.wrap(0, totalSlides);

        const transitionInSlide = ({ slide, direction = 1, duration = 1 }) => {
            gsap.fromTo(
                $slides[slide],
                {
                    xPercent: direction > 0 ? 100 : -100,
                },
                {
                    xPercent: 0,
                    duration,
                }
            );

            gsap.fromTo(
                $slidesImage[slide],
                {
                    xPercent: direction > 0 ? -100 : 100,
                },
                {
                    xPercent: 0,
                    duration,
                }
            );
        };

        const transitionOutSlide = ({ slide, direction = 1, duration = 1 }) => {
            gsap.to($slides[slide], {
                xPercent: direction > 0 ? -100 : 100,
                duration,
            });
            gsap.to($slidesImage[slide], {
                xPercent: direction > 0 ? 100 : -100,
                duration,
            });
        };

        const handlePrev = () => {
            const oldSlide = currentSlide;
            currentSlide = slidesWrap(currentSlide - 1);
            transitionInSlide({ slide: currentSlide, direction: -1 });
            transitionOutSlide({ slide: oldSlide, direction: -1 });
        };

        const handleNext = () => {
            const oldSlide = currentSlide;
            currentSlide = slidesWrap(currentSlide + 1);
            transitionInSlide({ slide: currentSlide });
            transitionOutSlide({ slide: oldSlide });
        };

        $slides.forEach(($slide, index) => {
            if (index === currentSlide) {
                transitionInSlide({ slide: index, duration: 0 });
                return;
            }

            transitionOutSlide({ slide: index, duration: 0 });
        });

        $buttonPrev.addEventListener('click', handlePrev);
        $buttonNext.addEventListener('click', handleNext);

        return () => {
            $buttonPrev.removeEventListener('click', handlePrev);
            $buttonNext.removeEventListener('click', handleNext);
        };
    }, []);

    return (
        <div>
            <SliderContainer>
                <Slide className="slide">
                    <SlideImage alt="bulbasaur" src="/images/menu/image.jpg" />
                </Slide>
                <Slide className="slide">
                    <SlideImage alt="charmander" src="/images/menu/image01.jpg" />
                </Slide>
                <Slide className="slide">
                    <SlideImage alt="squirtle" src="/images/menu/image03.jpg" />
                </Slide>
            </SliderContainer>

            <Button id="prev">Prev</Button>
            <Button id="next">Next</Button>
        </div>
    );
};

export default Slider;
