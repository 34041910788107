import {gsap, TimelineLite} from "gsap";
import {useLocation} from "react-router-dom";
import React, {useEffect, useLayoutEffect, useRef} from 'react';
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {DrawSVGPlugin} from "gsap/dist/DrawSVGPlugin";
import {useDispatch, useSelector} from "react-redux";
import {loading} from "../../api/redux/global";

const PageTransition = () => {

    gsap.registerPlugin(ScrollTrigger, ScrollSmoother, DrawSVGPlugin);
    const location = useLocation();
    let MainStore = useSelector(state => state)
    const dispatch = useDispatch()


    // preloader start on page/router change
    useEffect(() => {
        const tl = new TimelineLite()

        // dispatch(loading())
        tl.to('.page-loader', {
            opacity: 1,
            duration: 0.1,
            ease: 'Cubic.easeOut',
            display: 'flex'
        }).to('.page-loader svg', {
            duration: .4,
            opacity: 1
        }, '-=.7').fromTo('.page-loader svg line', {
            drawSVG: "0%",
        }, {
            // delay: .02,
            duration: 3,
            ease: 'Cubic.easeOut',
            stagger: .02
        })
    }, [location.pathname])

    // preloader end after page load
    useEffect(() => {
        const tl = new TimelineLite()
        tl.to('.page-loader', {
            delay: 2.5,
            opacity: 0,
            duration: .8,
            display: 'none'
        })
    }, [MainStore])

};

export default PageTransition;
