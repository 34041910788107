import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {hover} from "../../styles/globalStyle";
import {Img} from "../Img";
import Button from "../Button";
import ReactHtmlParser from "react-html-parser";
import {useLocation} from "react-router-dom";

const Mission = ({data}) => {
    // container padding
    const location = useLocation();
    const [offset,setOffset] = useState(0)


    useEffect(() => {
        if (window.innerWidth > 991) {
            setOffset(document.querySelector('.container').offsetLeft + 15)
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > 991) {
                setOffset(document.querySelector('.container').offsetLeft + 15)
            }
        })
    }, [location.pathname])


    return (
        <>
            <StyledDetail >
                <Container fluid className={`counter `} style={{paddingLeft: offset+15+'px'}}>
                    <Row>
                        <Col lg={3}  className="lube-testing__text">

                            {
                                data?.description && <p className="split-up">{ReactHtmlParser(data?.description)}</p>

                            }


                        </Col>
                        <Col lg={{span:8,offset:1}} className="lube-testing__img">
                            <div className="lube-testing__img__inner">
                                <Img src={data?.image ? data?.image : '/images/dynamic/home/concern.jpg'}/>
                            </div>

                            <div className={` lube-testing__img__box`}  style={{right: offset-15+'px'}}>
                                <div className="lube-testing__img__box__inner box ">

                                    <h3 className={''}> {data?.title} </h3>
                                    {
                                        data?.link ?
                                            <Button
                                                src={""}
                                                text={"Load More"}
                                                margin={"30px 0 0 0"}
                                                background={"transparent"}
                                                borderColor={'#F9F8F5'}
                                                color={"#F9F8F5"}
                                                hoverBackground={'#F9F8F5'}
                                                hoverColor={'#2D2D2D'}
                                                // hoverBackground={"#221F1F"}
                                            /> : ''
                                    }


                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </StyledDetail>
        </>
    );
};

const StyledDetail = styled.section`
  margin-bottom: 200px;

  .lube-testing__img {
    position: relative;
    padding: 0;

    &__inner {
      position: relative;
      padding-top: calc(540 / 868 * 100%);
      min-height: 100%;
    }

    &__box {
      //width: 40%;
      position: absolute;
      bottom: 0;
      transform: translateY(50%);
      padding-right: 15px;

      &__inner {
        //padding-top: calc(224 / 370 * 100%);
        position: relative;
        background-color: #2D2D2D;
        height: 224px;
        width: 370px;
        
        .global-image{
          background-repeat: no-repeat !important;
        }
        
        

        h3 {
          position: absolute;
          top: 40px;
          left: 40px;
          right: 40px;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          white-space: normal;
          overflow: hidden;
          //width: 70%;
          font-size: 40px;
          line-height: 48px;
          font-family: Kudryashev Display;
          color: #F9F8F5;
        }
      }

      .dc-btn {
        position: absolute;
        bottom: 40px;
        left: 40px;
        
      }
    }
  }

  .lube-testing__text {
    margin-top: 80px;
    //padding-left: 100px;
    padding-left: 0;

    p {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      color: #2D2D2D;
      font-family: Kudryashev Display;
    }
  }

  //responsive
  @media (min-width: 768px) and (max-width: 1150px) {

    .lube-testing__img__box {

      //width: 65%;
      //bottom: -80px;

      &__inner {
        .title {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }

    .lube-testing__text {
      //padding: 70px;
    }
  }
  @media (max-width: 992px) and (min-width: 768px) {
    .lube-testing__img__inner {

      position: relative;
      min-height: 100%;
      height: 100%;

      .image_wrapper {
        height: 100%;
      }
    }
  }
  @media (max-width: 991px) {
    margin-bottom: 100px;
    .dc-btn{
      padding-top: 15px;
    }
    
    
    .lube-testing__img__box {
      transform: translateY(0);
      &__inner {
        height: 100%;
      }
    }

    //margin-bottom: 0;
    .lube-testing__img {
      &__inner {
        padding-top: calc(360 / 375 * 100%);
      }
    }

    padding-bottom: 0;
    .row {
      //flex-direction: column;
      flex-direction: column-reverse;
      .counter {
        padding-top: 0 !important;
      }

      .col-sm-5,
      .col-sm-6 {
        min-width: 100%;
        margin: 0;
      }

      .lube-testing__img {
        &__box {
          width: 96%;
          position: relative;
          left: 15px !important;
          right: 15px !important;
          bottom: 75px !important;
          overflow: hidden;
          height: 159px !important;

          .dc-btn {
            left: 30px;
            bottom: 30px;
          }

          &__inner {
            width: 100%;

            .title {
              left: 30px !important;
              top: 30px !important;
              right: 30px !important;
              width: 85%;

              h2 {
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
              }
            }
          }
        }
      }

      .lube-testing__img,
      .lube-testing__text {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0px !important;
        margin: 0 !important;
      }

      .lube-testing__text {
        margin-top: -40px !important;
        padding: 0 15px !important;
      }
    }

  }
`;

export default Mission;
