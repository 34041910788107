import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import Title from "../Title";

const FeatureAmenities = ({data,padding}) => {



    return (
        <StyledFeatureAmenities id={''} className={'features pb-200'}>
            <Container>
                <Row>
                  <Col className={'features__col'} md={12}>
                      <Title small_text_color={'#222222'} margin={'0 0 40px'}
                             text={data?.title}/>
                      <div className="features__content">
                          <ul>
                              {
                                  data?.list && data?.list?.length>0 &&
                                  data?.list?.map((element)=>{
                                      return(
                                          <li>{element?.title}</li>
                                      )
                                  })
                              }

                          </ul>
                      </div>
                  </Col>
                </Row>
            </Container>
        </StyledFeatureAmenities>
    );
};
const StyledFeatureAmenities = styled.section`
  overflow: hidden;

  .features {
    &__content {
      ul {
        display: grid;
        grid-template-rows: repeat(6,auto);
        grid-auto-flow: column;
        gap: 0px;
        grid-template-columns: 50% calc(50% - 30px);
        justify-content: space-between;

        li {
          display: grid;
          padding: 15px 0;
          grid-template-rows: repeat(4, auto);
          grid-auto-flow: column;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #2D2D2D;
          border-bottom: 1px solid #AC8B7C;
          font-family: "Suisse Int'l" !important;


        }
      }
    }
  }

  
  @media(min-width: 1550px){
    .title{
      h2{
        font-size: 45px;
        line-height: 55px;
      }
    }
  }
  @media(max-width: 767px){
    .features{

      &__content {
        ul {
          display: flex;
          grid-template-rows:unset;
          grid-auto-flow: column;
          gap: unset;
          grid-template-columns: unset;
          flex-wrap: wrap;

          li {
            flex: 0 0 100%;
            display: block;
            padding: 15px 0px;
            grid-template-rows: repeat(4, auto);
            grid-auto-flow: column;
            font-weight: 400;
            font-size: 16px;
            color: #222222;
          }
        }
      }

    }
  }
`;
export default FeatureAmenities;