import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import ReactHtmlParser from "react-html-parser";


const Overview = ({data}) => {
    return (
        <StyledOverview className='overview'>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="overview__title">
                            <Title text={data?.title}/>
                        </div>
                    </Col>
                    <Col md={{span: 2, offset: 2}}>
                        <div className="overview__address">
                            <h3 className={'split-up'}>Address</h3>
                            {data?.location && <p className={'split-up'}>{ReactHtmlParser(data?.location)}</p>}
                        </div>
                    </Col>
                    <Col md={{span: 2, offset: 1}}>
                        <div className="overview__email">
                            <h3 className={'split-up'}>Email</h3>
                            {data?.email && <p className={'split-up'}><a href={`mailto:${data?.email}`}>{data?.email}</a></p>}
                        </div>
                    </Col>
                    <Col md={{span: 3, offset: 1}}>
                        <div className="overview__phone">
                            <h3 className={'split-up'}>Phone Number</h3>
                            {data?.phone && <p className={'split-up'}><a href={`tel:${data?.phone}`}>{data?.phone}</a></p> }
                        </div>
                    </Col>
                </Row>

            </Container>
        </StyledOverview>
    );
};

const StyledOverview = styled.section`
  background: #F9F8F5;
  padding-top: 100px;
  padding-bottom: 60px;
  .overview{
    &__address{
      p{
        font-size: 16px;
        line-height: 24px;
        font-family: Kudryashev Display;
        color: #2D2D2D;
        font-weight: 400;
      }
      h3{
        font-size: 24px;
        line-height: 32px;
        font-family: "Suisse Int'l";
        color: #2D2D2D;
        margin: 60px 0 25px 0;
      }
    }
    &__email{
      p{
        font-size: 16px;
        line-height: 24px;
        font-family: Kudryashev Display;
        color: #2D2D2D;
        font-weight: 400;
      }
      h3{
        font-size: 24px;
        line-height: 32px;
        font-family: "Suisse Int'l";
        color: #2D2D2D;
        margin: 60px 0 25px 0;
      }
    }
    &__phone{
      p{
        font-size: 16px;
        line-height: 24px;
        font-family: Kudryashev Display;
        color: #2D2D2D;
        font-weight: 400;
      }
      h3{
        font-size: 24px;
        line-height: 32px;
        font-family: "Suisse Int'l";
        color: #2D2D2D;
        margin: 60px 0 25px 0;
      }
    }
   
  }
  

  @media (max-width: 767px) {
    .overview{
      &__address{
        h3{
          margin: 30px 0 25px 0;
        }
      }
      &__email{
        h3{
          margin: 30px 0 25px 0;
        }
      }
      &__phone{
        h3{
          font-size: 24px;
          line-height: 32px;
          font-family: "Suisse Int'l";
          color: #2D2D2D;
          margin: 30px 0 25px 0;
        }
      }

    }
  }
`;

export default Overview;
