import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "./Title";
import Button from "./Button";
import ReactHtmlParser from "react-html-parser";
// import img from '../../public/images/static/leaf.svg'
const Overview = ({leaf, text, title , paddingTop, button,file,link,bg}) => {
    return (
        <StyledOverview className='overview pb-200'
                        paddingTop={paddingTop} bg={bg}
        >
            <Container>
                {
                    leaf ?
                        <div className="overview__icon new-parallax">
                            <img src="/images/static/leaf.svg" alt=""  />
                        </div> : ''
                }


                <Row>
                    <Col md={12}>
                        <div className="overview__title">
                            <Title text={title ? title : 'Title'}/>
                        </div>
                    </Col>
                    <Col md={{span: 8, offset: 2}}>
                        <div className="overview__text">

                            {
                                text &&  <p className={'split-up'}>{ReactHtmlParser(text)}</p>
                            }


                            {
                                button ?
                                    <Button text={  button ? button : 'About KHL'} src={link} download={file} target={'_blank'} background={'#F9F8F5'}/>
                                    : ''
                            }

                        </div>
                    </Col>
                </Row>

            </Container>
        </StyledOverview>
    );
};

const StyledOverview = styled.section`
  
  padding-top: ${props => props.paddingTop || '200px'};
  background-color: ${props => props.bg || ''};
  @media(max-width: 767px){
    padding-top: 120px;
  }

  position: relative;


  .overview__icon {
    img{
      height: 257px;
      width: 326px;
    }

    position: absolute;
    right: 15%;
    top: 15%;
  }


  .overview__text {
    margin-top: 70px;

    p {
      margin-bottom: 40px;
      margin-top: 30px;
      font-size: 16px;
      line-height: 24px;
      color: #2D2D2D;
      font-family: "Suisse Int'l";
      

    }
  }

  @media (max-width: 767px) {
    .overview__text {
      margin-top: 10px !important;
    }
    .overview__icon{
      transform: translate(40%, -60%);
      img{
        height: 126px;
        width: 160px;
      }
    }
    
  }
`;

export default Overview;
