import React from 'react';
import styled from "styled-components";
import ProjectSlider from "./home/ProjectSlider";
import Video from "./home/Video";

const Overview = ({video,title,data}) => {
    return (
        <StyledOverview className='overview' video={video?.bg_image}>
       <>
       <ProjectSlider data={data} title={title}/>
       <Video data={video} />
       </>
        </StyledOverview>
    );
};

const StyledOverview = styled.div`
  background: url(${props => props.video || '/images/dynamic/home/bg.jpg'});

  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% 72.5%;
  
  @media(max-width: 1499px){
    background-size: 100% 74%;
  }
  @media(max-width: 1024px){
    background-size: 100% 68%;
  }
  @media(max-width: 991px){
    background-size: 100% 68%;
    h2{
      br{
        display: none;
      }
    }
  }
  //
  @media(max-width: 767px){
    background-size: 100% 63%;
  }

`;

export default Overview;
