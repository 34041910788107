import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import {title} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import Title from "../Title";


const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'directors pt-200 pb-200'}>
            <Container>
                <Row>
                    <Col md={12}>
                        <Title color={'#FFFEFB'}  margin={'0 0 40px 0px'} fontSize={'60'} fontWeight={'500'}
                               lineHeight={'65'} text={data?.title}/>
                    </Col>
                </Row>
            </Container>
            <Container>
                {
                    data?.list && data?.list.length>0 &&
                    data?.list.map((element)=>{
                        return(
                            <div className="directors__single parallax-box">
                                <Row>
                                    <Col lg={5}>
                                        <div className={'directors__single__img reveal '}>
                                            <Img src={element?.image}/>
                                        </div>
                                    </Col>
                                    <Col lg={7} className={'directors__single__content'}>
                                        <div className="directors__single__name fade-up">
                                            {element?.name && <h3 className={'split-up'}>{reactHtmlParser(element?.name)}</h3>}
                                            {element?.designation && <h6 className={'split-up'}>{reactHtmlParser(element?.designation)}</h6>}
                                            {element?.description && <p className={'split-up'}>{reactHtmlParser(element?.description)}</p>}

                                        </div>
                                    </Col>

                                </Row>
                            </div>
                        )
                    })
                }

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #2D2D2D;

  p {
    color: #ffffff;
  }

  .directors__single__content {
    padding-left: 100px;
    padding-top: 0px;
    padding-bottom: 70px;

    h3 {
      font-size: 24px;
      line-height: 32px;
      font-family: Kudryashev Display;
      color: #FFFEFB;
      font-weight: 500;
    }

    h6 {
      font-size: 16px;
      line-height: 24px;
      font-family: Kudryashev Display;
      color: #FFFEFB;
      font-weight: 500;
      margin-bottom: 60px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #F9F5F0;
      font-family: "Suisse Int'l";
    }

  }

  .directors__single {
    margin-bottom: 120px;

    &__img {
      padding-top: calc(450 / 500 * 100%);
      position: relative;

      .reveal {
        height: 100%;
      }

    }


    &:last-child {
      margin-bottom: 0 !important;
    }

    &:nth-of-type(even) {
      .row {
        flex-direction: row-reverse;
      }

      .directors__single__content {
        padding-right: 100px;
        padding-left: 15px;
      }
    }
  }

  @media (max-width: 991px) {
    .col-sm-6 {
      min-width: 100%;
    }

    .row {
      flex-direction: column !important;
    }

    .directors__single__content {
      padding: 0 15px !important;
      margin-top: 30px;

      h6 {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 767px) {
    .title{
      margin: 0 0 40px 0;
    }

    .directors__single {
      margin-bottom: 60px !important;

      &:last-child {
        margin-bottom: 0;
      }

      .row {
        flex-direction: column !important;

        .col-sm-6:first-child {
          padding: 0;
        }
      }
    }
  }

`;

export default MyComponent;
