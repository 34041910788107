import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchPosts} from "../../api/redux/post";
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/Overview";
import MissionVision from "../../components/about/MissionVision";
import OurValuesSlider from "../../components/about/OurValuesSlider";
import Leader from "../../components/about/Leader";
import DynamicTeam from "../../components/about/DynamicTeam";
import {fetchAbout} from "../../api/redux/about";

const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.ABOUT
        dispath(fetchAbout([api_url]))
    }, [])

    let getPost = useSelector(state => state.about)


   //Data Refactor

    let banner=getPost?.data?.[0]?.banner;
    let overview=getPost?.data?.[0]?.overview;
    let mission_vision=getPost?.data?.[0]?.mission_vision;
    let values=getPost?.data?.[0]?.values;
    let leaders=getPost?.data?.[0]?.leaders;
    let teams=getPost?.data?.[0]?.teams;


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>About | Kopotakkho Homes Ltd.</title>
                <meta name="description" content="Step into the extraordinary realm of Kopotakkho Homes Ltd, a unique powerhouse of real estate in Bangladesh. We have been narrating uniquely-designed residential projects all around Bangladesh since 2005. Transcending the expertise to architect a bespoke look and conceptualizing modern lifestyle keeping budgets within reach. Experience the combination of perfection & convenience, only with us!"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner img={banner?.image} title={banner?.title}/>
                <Overview title={overview?.title} text={overview?.description} paddingTop={'200px'}/>
                <MissionVision data={mission_vision}/>
                <OurValuesSlider data={values}/>
                <Leader data={leaders}/>
                <DynamicTeam data={teams}/>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
