import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchPosts} from "../../api/redux/media";
import InnerBanner from "../../components/InnerBanner";
import MediaListing from "../../components/media/MediaListing";

const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.MEDIA
        dispath(fetchPosts([api_url]))
    }, [])

    let getPost = useSelector(state => state.media);

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Media | Kopotakkho Homes Ltd.</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner title={getPost?.data?.page_data?.banner?.title} img={getPost?.data?.page_data?.banner?.image ? getPost?.data?.page_data?.banner?.image:'/images/dynamic/media/banner.jpg'}  />
                <MediaListing data={getPost?.data?.media_list} />

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
