import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail} from "../../api/redux/post";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import Overview from "../../components/Overview";
import InnerBanner from "../../components/InnerBanner";
import AtGlance from "../../components/projects/AtGlance";
import FeatureAmenities from "../../components/projects/FeatureAmenities";
import FloorPlanSlider from "../../components/projects/FloorPlanSlider";
import Gallery from "../../components/projects/Gallery";
import Map from "../../components/contact/Map";
import {fetchProjectDetail} from "../../api/redux/project";
import ReactHtmlParser from "react-html-parser";

const MyComponent = () => {

    const dispatch = useDispatch()
    const getData = useSelector(store => store.project)
    let {slug} = useParams();


    // api call
    useEffect(() => {
        let api_url = apiEndPoints.PROJECT_DETAIL
        dispatch(fetchProjectDetail([api_url + `/${slug}`]))
    }, [])

    //data Refactor

    let banner=getData?.detail?.[0]?.banner;
    let glance=getData?.detail?.[0]?.at_a_glance;
    let feature=getData?.detail?.[0]?.feature;
    let floorPlan=getData?.detail?.[0]?.floor_plan;
    let gallery=getData?.detail?.[0]?.gallery;
    let map=getData?.detail?.[0]?.map;
    let overview=getData?.detail?.[0]?.overview;

    return (

        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getData?.detail[0]?.banner?.title ? getData?.detail[0]?.banner?.title : 'Kopotakkho Homes Ltd.'}</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner img={banner?.image ? banner?.image : '/images/dynamic/project/details/banner.jpg'} title={banner?.title}
                    text = {
                        `${banner && banner.project_type ? banner.project_type : ''}
                            ${banner && banner.project_type && banner.project_location ? '<span class="dot"></span>' : ''}
                            ${banner && banner.project_location ? banner.project_location : ''}`
                    }

                />
                {
                    overview?.title &&  overview?.description ?
                    <Overview
                    title={overview?.title}
                    text={overview?.description}
                    button={'Download brochure'} paddingTop={'100px'}
                    file={overview?.brochure_file}
                    /> : ''
                }

                {
                    glance?.description &&  <AtGlance data={glance} />
                }
                {
                    feature?.list?.length>0 ? <FeatureAmenities data={feature}/> : ''
                }
                {
                    floorPlan?.list?.length>0 ? <FloorPlanSlider data={floorPlan} /> : ''
                }
                {
                    gallery?.list?.length>0 ? <Gallery data={gallery}/> : ''
                }
                {
                    map?.image && map?.url ?
                        <Map img={map?.image} link={map?.url}/> :''
                }
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`
    background: #F9F8F5;

  .dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: white; /* Replace with your desired color */
    border-radius: 50%;
    margin: 0 5px; /* Adjust the margin to position the dot */
  }

`;

export default MyComponent;
