export const apiEndPoints = {
    HOME: 'page/home',
    ABOUT: 'page/about',
    CONTACT: 'page/contact',
    CAREER: 'page/career',
    PROJECT: 'page/projects',
    PROJECT_DETAIL: 'project',
    MEDIA: 'page/media',
    GLOBAL: 'page/global',
    CONTACT_FORM:'contact-form-7/v1/contact-forms/7/feedback',
    CAREER_FORM:'contact-form-7/v1/contact-forms/516/feedback',

};
