import React from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {Col, Container, Row} from "react-bootstrap";
import Title from "./Title";
import reactHtmlParser from "react-html-parser";
import VisibilitySensor from "react-visibility-sensor";
import Button from "./Button";
import {Link} from "react-router-dom";

const Overview = ({img, text, title, src,type,location}) => {
    return (
        <StyledOverview className='project-box'>
            <Link to={src}>
                <div className="project-box__single">
                    <div className="project-box__single__image reveal ">
                        {/*<Img src={img? img : '/images/dymanic/home/projects/1.jpg'} />*/}
                        <Img src={img ? img : ''} />
                    </div>
                    <div className="project-box__single__text">
                        {
                            title && <h6 className={'split-up'} >{title ? title : ''}</h6>
                        }

                        <div className={'d-flex align-items-center'}>
                            {
                                type && location ? <>
                                    <p>{type}</p>
                                    <div className={'dot'}></div>
                                    <p>{location}</p>
                                </> : ''
                            }

                        </div>

                    </div>
                    <div className="project-box__single__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
                            <defs>
                                <filter id="Path_9520" x="0" y="0" width="70" height="70" filterUnits="userSpaceOnUse">
                                    <feOffset dx="1" dy="2" input="SourceAlpha"/>
                                    <feGaussianBlur stdDeviation="3" result="blur"/>
                                    <feFlood floodOpacity="0.231"/>
                                    <feComposite operator="in" in2="blur"/>
                                    <feComposite in="SourceGraphic"/>
                                </filter>
                            </defs>
                            <g id="Group_19849" data-name="Group 19849" transform="translate(-490 -6768)">
                                <g transform="matrix(1, 0, 0, 1, 490, 6768)" filter="url(#Path_9520)">
                                    <circle id="Circle_9520" data-name="Circle 9520" cx="35" cy="35" r="26" fill="#FFF"/>
                                    <circle id="Circle_9520" data-name="Circle 9520" cx="35" cy="35" r="0" fill="#AC8B7C"/>
                                </g>
                                <g id="Group_18999" data-name="Group 18999" transform="translate(-127.5 -87.5)">
                                    <line id="Line_12372" data-name="Line 12372" y2="10" transform="translate(651.5 6885.5)" fill="none" stroke="#2d2d2d" strokeLinecap="round" strokeWidth="2"/>
                                    <line id="Line_12373" data-name="Line 12373" y2="10" transform="translate(656.5 6890.5) rotate(90)" fill="none" stroke="#2d2d2d" strokeLinecap="round" strokeWidth="2"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            </Link>
        </StyledOverview>
    );
};

const StyledOverview = styled.div`
  overflow: hidden;
    .project-box__single{

      position: relative;
      margin-bottom: 30px;
      a {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        cursor: pointer;
      }

      &:hover {
        .global-image {
          transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
          transform: scale(1.06);
        }
      }
      
      &__image{
        position: relative;
        padding-top: calc(510 / 370 * 100%);
        overflow: hidden;

        .global-image {
          overflow: hidden;
          transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
        }
      }
      &__text{
        position: absolute;
        bottom: 20px;
        left: 30px;
        h6{
          font-size: 23px;
          line-height: 36px;
          color: #FFFF;
          font-weight: 400;
          font-family: "Suisse Int'l";
        }
        p{
          display: flex;
          font-size: 15px;
          line-height: 23px;
          color: white;
          font-weight: 400;
          font-family: "Suisse Int'l";
        }
      }
      &__icon{
        position: absolute;
        bottom: 35%;
        left: 50%;
        transform: translate(-50% , -50%);
        cursor: pointer;
        z-index: 999999;
          svg{
            position: relative;
            #Circle_9520{
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            }

          }
        
        
        &:hover{
         
         svg{
           #Circle_9520{
             r: 26;
             transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
           }
         }
        }

        
        
        
      }
    }

  .dot{
    display: flex;
    justify-content: end;
    align-items: end;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #FFFFFF;
    margin-right: 10px;
    margin-left: 10px;
  }

`;

export default Overview;
