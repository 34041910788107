import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {hover} from "../../styles/globalStyle";
import Title from "../Title";
import {Img} from "../Img";
import Button from "../Button";

const Map = ({img,link}) => {

    const [offset, setOffset] = useState();
    useEffect(() => {
        setOffset(document.querySelector(" .container").offsetLeft);
    }, []);

    return (

        <StyledDetail className={'map'}>
            <Container fluid>
                <Row>
                    <Col className={'map__img'}>
                        <a href={`${link}`} target={'_blank'}>
                            <Img src={img} />
                        </a>
                    </Col>
                </Row>
            </Container>
        </StyledDetail>

    );
};

const StyledDetail = styled.section`
.map__img{
  position: relative;
  padding-top: calc(500 / 1366 * 100%);
}
  
  


  @media(max-width: 767px){
 .map__img{
   padding-top: calc(40.6032%);
 }
  }

`;

export default Map;
