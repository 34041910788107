import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Button from "../components/Button";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {fetchPosts} from "../api/redux/post";
import ProjectBox from "../components/ProjectBox";
import Text from "../components/ProjectVideoMerge";
import Leader from "../components/about/Leader";
import ValuesBox from "../components/ValuesBox";
import {Col, Container, Row} from "react-bootstrap";
import TextSlider from "../components/TextSlider";



const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.POSTS
        dispath(fetchPosts([api_url]))
    }, [])

    let getPost = useSelector(state => state.posts)

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Title</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
                {/*<h1>this is component page</h1>*/}
                {/*<Button text={'Checking the size of Button'} />*/}
                <Container fluid>
                    <Row>
                        <Col md={5}>
                            <TextSlider />
                        </Col>
                    </Row>
                </Container>


            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
