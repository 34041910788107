import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import Button from "../Button";
import React, {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import PopupV1 from "../PopUpModal";
import 'react-modal-video/css/modal-video.min.css';
import Media from "../media/Media";
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";

const BlogSliderV1 = ({data}) => {

    let containerRef = useRef();
    let sliderRef = useRef();
    const [current, setCurrent] = useState('')
    let [offset, setOffset] = useState(90)
    let [theWidth, SetTheWidth] = useState(0)
    let [activeNumber, setActiveNumber] = useState(1)
    let [totalNumber, setTotalNumber] = useState(5)


    useEffect(() => {
        setOffset(containerRef.current?.offsetLeft)
        window.addEventListener('resize', () => {
            setOffset(containerRef.current?.offsetLeft)
            SetTheWidth(window.innerWidth)
        })

        // slider number
        const getActiveItem = document.querySelector('.swiper-pagination-current')?.innerHTML
        const getTotalItem = document.querySelector('.swiper-pagination-total')?.innerHTML
        setActiveNumber(getActiveItem)
        setTotalNumber(getTotalItem)
    }, [null])


    useEffect(() => {
        const getTotalItem = 5
        setTotalNumber(getTotalItem)
    }, [data])
    let sliderNumber = () => {
        const getActiveItem = document.querySelector('.swiper-pagination-current').innerHTML
        setActiveNumber(getActiveItem)
    }

    const [initialIndex, setIndex] = useState( 6)

    const PhotoItem = ({image, thumb, group, data}) => (
        <LightgalleryItem group={group} src={image} thumb={thumb}>
            <Media img={image} data={data} photoGallery/>
        </LightgalleryItem>

    );
    const PhotoItem2 = ({image, thumb, group}) => (
        <div style={{maxWidth: "250px", width: "200px", padding: "5px"}}>
            <LightgalleryItem group={group} src={image} thumb={thumb}>
                <img src={image} style={{width: "100%"}}/>
            </LightgalleryItem>
        </div>
    );

    let [videoId, setVideo] = useState('');

    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
        setVideo(true)
    }


    return (
        <StyledBlog offset={offset} className='blog-slider'>
            <Container ref={containerRef}>
                <Row>
                    <Col md={12}>
                        <div className="blog-button">
                            <Col md={9}>
                                <Title text={'Our latest event </br> <span>& media updates</span>'}/>
                            </Col>
                            <Col md={3}>
                                <div className="blog-button__right">
                                    <div>
                                        <Button text={'View All'} src={'/media'}/>
                                    </div>
                                    <div className="slider-nav">
                                        <ul>
                                            <li className="prevhover slider_prev1">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                                     viewBox="0 0 16 16" height="1em" width="1em"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd"
                                                          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"></path>
                                                </svg>
                                            </li>
                                            <li className="nexthover slider_next1">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                                     viewBox="0 0 16 16" height="1em" width="1em"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd"
                                                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                                                </svg>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>

            <div className={` fade-up blog-slider__slider-wrap`}>
                <div className="blog-slider__slider-wrap__inner">
                    {
                        data && data?.length>0 ?
                            <Swiper loop={true}
                                    spaceBetween={30}
                                    slidesPerView={2}
                                    allowSlideNext={true}
                                    allowSlidePrev={true}
                                    allowTouchMove={true}
                                    speed={900}
                                    pagination={{
                                        type: "fraction",
                                    }}
                                    navigation={{
                                        prevEl: '.slider_prev1',
                                        nextEl: '.slider_next1',
                                    }}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1,
                                            spaceBetween: 30,
                                        },
                                        767: {
                                            slidesPerView: 2,
                                            spaceBetween: 30,
                                        },
                                        1024: {
                                            slidesPerView: 2,
                                            spaceBetween: 30,
                                        },
                                    }}
                                    onSlideChange={(s) => sliderNumber()}
                                    ref={sliderRef}>
                                {
                                    data?.length > 0 && data.slice(0, initialIndex)?.map((element, index) => (
                                        element.detail_info?.video_id ?
                                            <SwiperSlide key={`video_${element.ID}_${index}`}>
                                                <PopupV1 show={show}  video={true} direct_video={element.detail_info.video_id} handleClose={handleClose}/>
                                                <div onClick={() => handleShow(true)}>
                                                    <Media img={element.detail_info.thumb} data={element}/>
                                                </div>
                                            </SwiperSlide> :
                                            <SwiperSlide key={`photo_${element.ID}_${index}`}>
                                                <LightgalleryProvider
                                                    lightgallerySettings={{
                                                        download: false,
                                                        fullScreen: true,
                                                        share: false
                                                    }}
                                                >
                                                    <div>
                                                        <PhotoItem key={`photo_${element.ID}`} image={element.detail_info.list[0].image} group={`group${element.detail_info.list[0].ID}`} data={element}/>
                                                        <div style={{ display: "none", alignItems: "center", justifyContent: "center" }}>
                                                            {element.detail_info.list && element.detail_info.list.slice(1).map((photoElement, photoIndex) => (
                                                                <PhotoItem2 key={`photo_${photoElement.ID}_${photoIndex}`} image={photoElement.image} group={`group${photoElement.ID}`} />
                                                            ))}
                                                        </div>
                                                    </div>
                                                </LightgalleryProvider>
                                            </SwiperSlide>
                                    ))

                                }

                            </Swiper>
                            :''
                    }
                </div>

            </div>
        </StyledBlog>
    );
};

const StyledBlog = styled.section`
  padding-bottom: 200px;
  .blog-button {
    display: flex;
    justify-content: space-between;
    margin-bottom: 85px;
    align-items: end;
    
    .col-md-3{
      padding: 0;
    }
    
    &__right{
      justify-content: space-between;
      display: flex;
      width: 100%;
    }
    
    .slider-nav {
      ul {
        display: flex;
      }

      li {
        height: 40px;
        width: 40px;
        //background-color: rgb(34, 31, 31);
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        border: 1px solid black;

        &:nth-of-type(1) {
          margin-right: 20px;
        }

        svg {
          z-index: 2;
        }

        &:hover {
          border: 1px solid #AC8B7C;
        }

      }
    }
  }

  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
    background-color: red;
  }

  .swiper-button-next, .swiper-button-prev, .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }

  .swiper-initialized {
    //padding-right: 300px;
    //padding-left: 300px;
    //margin-right: ${props => props.offset + 15}px;
    margin-left: ${props => props.offset + 15}px;
    margin-right: ${props => props.offset + 15}px;
  }

  .slider-nav-mobile {
    display: none;
  }

  .blog-slider {
    &__slider-wrap {
      &__inner {
        .blog-single {
          &__inner {
            padding-top: 115%;
          }
        }
      }
    }
  }
  .col-md-9{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  @media (max-width: 767px) {
    .blog-button__right{
      margin-top: 30px;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    .swiper-container {
      margin-left: 0;
      padding-right: 0;
    }

    .blog-slider {
      &__slider-wrap {
        //margin-left: 15px;
        //margin-right: 15px;

        .slider-nav-mobile {
          margin-top: 40px;

          ul {
            display: flex;
          }

          li {
            height: 50px;
            width: 50px;
            //background-color: black;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;

            &:nth-of-type(1) {
              margin-right: 20px;
            }

            svg {
              color: #ffffff;
              z-index: 2;
            }
          }
        }
      }
    }

    .blog-button {
      margin-bottom: 40px;
      display: block;

      .slider-nav {
        //margin-top: 30px;
      }
    }

    .slider-nav-mobile {
      display: block;
    }
    .col-md-9{
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

  }

`;
export default BlogSliderV1;