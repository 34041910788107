import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post'
import globalReducer from '../redux/global'
import homeReducer from '../redux/home'
import aboutReducer from '../redux/about'
import contactReducer from '../redux/contact'
import mediaReducer from '../redux/media'
import careerReducer from '../redux/career'
import projectReducer from '../redux/project'
import menufooterReducer from '../redux/menu'

const store = configureStore({
    reducer: {
        postReducer,
        globalReducer,
        home:homeReducer,
        about:aboutReducer,
        contact:contactReducer,
        media:mediaReducer,
        project:projectReducer,
        career:careerReducer,
        social: menufooterReducer
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

