import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory, Link, useLocation
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail, fetchPosts} from "../../api/redux/post";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import InnerBanner from "../../components/InnerBanner";
import ProjectList from "../../components/projects/ProjectsList";
import {fetchProject} from "../../api/redux/project";
import {ApiParam} from "../../api/network/apiParams";

const MyComponent = () => {

    const dispath = useDispatch()

    let getPost = useSelector(state => state.project)
    let banner=getPost?.data?.[0]?.banner;
    let list=getPost?.data?.[0]?.list;
    let filter=getPost?.data?.[0]?.filter;

    //get the query
    const locations = useLocation();
    const project_status = new URLSearchParams(locations?.search).get("status");


    // step -01

    const [selectStatus, setSelectStatus] = useState(project_status)
    const [selectType, setSelectType] = useState('')
    const [selectLocation, setSelectLocation] = useState('')

    // step -02

    // api call
    useEffect(() => {

        let param = {
            [ApiParam.project_status]: selectStatus,
            [ApiParam.project_type]: selectType,
            [ApiParam.project_location]: selectLocation
        }
    }, [selectStatus, selectType, selectLocation])

    // api call
    useEffect(() => {

        let param = {
            [ApiParam.project_status]: selectStatus,
            [ApiParam.project_type]: selectType,
            [ApiParam.project_location]: selectLocation
        }
        let api_services = apiEndPoints.PROJECT
        dispath(fetchProject([api_services,param]))
    }, []);




    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Projects | Kopotakkho Homes Ltd.</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    title={banner?.title}
                    img={banner?.image}/>

                <ProjectList data={list} option={filter} />

            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
