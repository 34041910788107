import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import {Link} from "react-router-dom";
import Img from "../Img";

const Gallery = ({data, padding}) => {
    const PhotoItem = ({image, group}) => (
        <LightgalleryItem group={group} src={image}>
            <Img  src={image}/>
        </LightgalleryItem>
    );


    return (
        <StyledGallery className={`gallery ${padding ? padding : 'pt-120 pb-120'}`}>
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="gallery__title">
                            <Title text={data?.title} margin={'0 0 40px 0'} fontWeight={"400"}/>
                        </div>
                    </Col>
                </Row>
            </Container>


            <Container fluid className={'gallery__image'}>
                <Row>
                    <LightgalleryProvider>
                        {
                            data?.list && data?.list.length>0 &&
                            data?.list.map((element,index)=>{
                                return(
                                    <Col lg={3} sm={6} key={index}>
                                        <div className="fixed_height">
                                            <PhotoItem image={element?.image} group={'1'}/>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </LightgalleryProvider>
                </Row>
            </Container>
        </StyledGallery>
    );
};
const StyledGallery = styled.section`
  margin-bottom: -30px;
  overflow: hidden;
  
  .row{
    gap: 10px;
  }  
  .fixed_height{
    padding-top: calc(300/333 *100%);
  }
  .gallery__image {
    margin-bottom: 30px;

    .row {
      margin-left: -15px;
      margin-right: -15px;
      @media(max-width: 767px){
        margin-left: -23px;
        margin-right: -23px;
      }
    }

    @media (min-width: 768px) and (max-width: 1550px) {
      .col-lg-3 {
        padding: inherit;
        margin-bottom: 0px;
        margin-right: 0px;
        flex: 0 0 calc(25% - 10px);
        max-width: calc(25% - 10px);
      }
    }
    @media (min-width: 1551px){
      .col-lg-3 {
        padding: inherit;
        margin-bottom: 0px;
        margin-right: 0px;
        flex: 0 0 calc(25% - 10px);
        max-width: calc(25% - 10px);
      }
    }
    @media (max-width: 767px) {
      .col-sm-6 {
        padding: inherit;
        margin-bottom: 0px;
        margin-right: 0px;
        flex: 0 0 calc(50% - 10px);
        max-width: calc(50% - 10px);
      }
    }
    .single {
      cursor: pointer;
    }
  }

  @media (max-width: 767px) {
    .title {
      h2 {
        text-align: start;
      }
    }
  }
  
  @media (min-width: 1550px) {
    .title {
      h2 {
        font-size: 45px;
        line-height: 55px;
      }
    }
  }
`

export default Gallery;