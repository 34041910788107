import React, {useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";

import {Img} from "./Img";
import ReactHtmlParser from "react-html-parser";


const luxury = ({img, number, text , hovertext}) => {

    return (
        <Styeldluxury className={``}>
            {/*<Container>*/}
            {/*    <Row>*/}
            {/*        <Col md={4} className={'single'}>*/}
                        <div className="single_wrp">
                            {/*<div className="reveal">*/}
                            <Img src={ img ? img : '/images/dynamic/about/trans.jpg'}/>
                            {/*</div>*/}
                            <div className="content">
                                <span className={'split-up'} >{number ? number : '1'}</span>
                                <p className={'split-up'} >{text ? text : 'Transparency'}</p>
                                <div className={'view-details  '}>
                                    {ReactHtmlParser(hovertext ? hovertext : 'Being honest, ethical, and trustworthy with ourselves and our business.')}

                                </div>
                            </div>
                        </div>



        </Styeldluxury>
    );
};

const Styeldluxury = styled.div`
  background-color: #F7F3F0;
  position: relative;
  
  .container {
    position: relative;
    z-index: 3;
  }

  .title {
    h2 {
      color: white;
    }

    p {
      color: white;

      span {
        color: white;
      }

      &:after {
        background: white;
      }
    }
  }

  .single_wrp {
    cursor: pointer;
    position: relative;

    &:before {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      z-index: 2;
      background: rgba(5, 7, 13, 0.4);
      content: '';
      opacity: 0;
      transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);

    }

    overflow: hidden;
    padding-top: calc(379 / 270 * 100%);

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 50vh;
      z-index: 1;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    }

    .content {
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      bottom: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 120px;
      justify-content: center;

      p {
        font-size: 24px;
        font-weight: bold;
        line-height: 50px;
        color: #F7F3F0;
        font-family: Articulat CF;
        margin: 0;
        transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
        transform: translateY(25px);
      }

      .view-details {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #F9F8F5;
        margin: 0 44px;
        opacity: 0;
        visibility: hidden;
        transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
        transform: translateY(25px);
        text-align: center;
        font-family: Articulat CF;

      }

      span {
        font-size: 150px;
        font-weight: 600;
        line-height: 160px;
        color: rgba(247, 243, 240, 0.5);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
        font-family: Articulat CF;

      }
    }

    .global-image {
      img {
        transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
        transform: scale(1.01);
      }
    }

    &:hover {
      &:before {
        opacity: 1;
      }

      .content {
        p {
          transform: translateY(0px);

        }
      }

      .view-details {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);

      }

      .global-image {
        img {
          transform: scale(1.12);
        }
      }
    }
  }


  @media (max-width: 767px) {
    //padding-top: 90px;
    .single {
      max-width: 100%;
      flex: 0 0 100%;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

`;

export default luxury;
