import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get} from "../../network/axiosServices";


const initialState = {
    loading: false,
    data: [],
    error: '',
    detail: [],
    detailLoading: false,
    detailError: ''
}

export const fetchProject = createAsyncThunk("fetchProject", (params) => {
    return get(params);
});

export const fetchProjectDetail = createAsyncThunk("fetchProjectDetail", (params) => {
    return get(params);
});


const postSlice = createSlice({
    name: 'posts',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchProject.pending, (state) => {
            state.loading = true
            state.data = []
            state.error = ''
        })
        builder.addCase(fetchProject.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ''
        })
        builder.addCase(fetchProject.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error
        })
        builder.addCase(fetchProjectDetail.pending, (state, action) => {
            state.detailLoading = true
            state.detail = []
            state.detailError = ''
        })
        builder.addCase(fetchProjectDetail.fulfilled, (state, action) => {
            state.detailLoading = false
            state.detail = action.payload
            state.detailError = ''
        })
        builder.addCase(fetchProjectDetail.rejected, (state, action) => {
            state.detailLoading = false
            state.detail = []
            state.detailError = action.error
        })
    }
})


export default postSlice.reducer
