import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchPosts} from "../../api/redux/post";
import InnerBanner from "../../components/InnerBanner";
import Map from "../../components/contact/Map";
import ContactOverview from "../../components/contact/ContactOverview";
import ContactForm from "../../components/contact/ContactForm";
import {fetchContact} from "../../api/redux/contact";

const MyComponent = () => {
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CONTACT
        dispath(fetchContact([api_url]))
    }, [])

    let getPost = useSelector(state => state.contact);

    //Data Refactor

    let banner=getPost?.data?.[0]?.banner;
    let address=getPost?.data?.[0]?.address;
    let form=getPost?.data?.[0]?.form;



    return (

        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Contact | Kopotakkho Homes Ltd.</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner img={banner?.image} title={banner?.title}  />
                <ContactOverview data={address}/>
                <Map img={address?.image} link={address?.gmap_link}/>
                <ContactForm title={form?.title} img={form?.image}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
