import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import 'swiper/css';
import "swiper/css/pagination";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Navigation, EffectCreative, Autoplay} from "swiper";
import {Img} from "../Img";
import ReactHtmlParser from "react-html-parser";
import reactHtmlParser from "react-html-parser";


const MyComponent = ({background, data}) => {
    const nextsRef = useRef()
    const prevsRef = useRef()

    const [current, setCurrent] = useState(1)

    const handleSliderCurrent = () => {
        setTimeout(() => {
            if (document.querySelector('.amenities__big .swiper-pagination-current')) {
                setCurrent(document.querySelector('.amenities__big .swiper-pagination-current').innerHTML)
            }

        }, 200)
    }

    return (
        <StyledComponent background={background} className={'amenities pb-200 as-image-text-slider'}>
            <Container>

                <div className="slider-nav">
                    <ul>
                        <li ref={prevsRef} className="prevhover slider_prev" >
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0"
                                 viewBox="0 0 16 16" height="1em" width="1em"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"></path>
                            </svg>
                        </li>
                        <li ref={nextsRef} className="nexthover slider_next" >
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0"
                                 viewBox="0 0 16 16" height="1em" width="1em"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                            </svg>
                        </li>
                    </ul>

                    <p>{current}<span>/{data?.list.length}</span>
                    </p>
                </div>

                <Row>
                    <Col>
                        <Title text={data?.title} margin={'0 0 70px 0'}/>
                    </Col>
                </Row>

                <Row>
                    <Col className={'amenities__big'} lg={{span: 4, offset: 2}}>
                        <div className="amenities__big__slider">

                            <img className="shadow-right"
                                 src={'/images/static/quotes.svg'}
                                 alt=""/>

                            {data?.list && data?.list.length>0 ?
                                <Swiper
                                    modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                                    loop={false}
                                    allowTouchMove={false}
                                    autoplay={false}
                                    initialSlide={0}
                                    pagination={{
                                        // el: '.swiper-pagination',
                                        type: "fraction",
                                    }}
                                    navigation={{
                                        prevEl: prevsRef.current,
                                        nextEl: nextsRef.current,
                                    }}
                                    onBeforeInit={(swiper) => {
                                        swiper.params.navigation.prevEl = prevsRef.current;
                                        swiper.params.navigation.nextEl = nextsRef.current;
                                    }}

                                    speed={900}
                                    onSlideChange={(s) => handleSliderCurrent()}
                                >
                                    {
                                        data?.list && data?.list.length>0 &&
                                        data?.list?.map((element,index)=>{
                                            return(
                                                <SwiperSlide key={index}>
                                                    <div className="single">
                                                        <div className="single__img ">
                                                            <Img src={element?.image}/>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }

                                </Swiper>

                                :''
                            }


                        </div>

                    </Col>

                    <Col className={'amenities__right'} lg={{span: 5, offset: 1}}>
                        {
                            data?.list && data?.list.length>0 ?
                                <Swiper
                                    modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                                    loop={false}
                                    allowTouchMove={false}
                                    autoplay={false}
                                    initialSlide={0}
                                    pagination={{
                                        // el: '.swiper-pagination',
                                        type: "fraction",
                                    }}

                                    navigation={{
                                        prevEl: prevsRef.current,
                                        nextEl: nextsRef.current,
                                    }}
                                    onBeforeInit={(swiper) => {
                                        swiper.params.navigation.prevEl = prevsRef.current;
                                        swiper.params.navigation.nextEl = nextsRef.current;
                                    }}

                                    speed={900}
                                    onSlideChange={(s) => handleSliderCurrent()}

                                >
                                    {
                                        data?.list && data?.list.length>0 &&
                                        data?.list?.map((element,index)=>{
                                            return(
                                                <SwiperSlide key={index}>
                                                    <p className={'split-up'}>
                                                        {reactHtmlParser(element?.description)}
                                                    </p>

                                                    <h4 className={'split-up'}>{reactHtmlParser(element?.name)}</h4>
                                                    <h6 className={'split-up'}>{reactHtmlParser(element?.designation)}</h6>
                                                </SwiperSlide>
                                            )
                                        })
                                    }


                                </Swiper>

                                :""
                        }

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  overflow: hidden;
  background-color: ${p => p.background ? p.background : '#FFF'};

  .container {
    position: relative;

    .slider-nav {
      
  
    }
  }

  .amenities__big {
    position: relative;

    &__slider {
      position: relative;
      background-color: #DDD;

      .shadow {
        position: absolute;
        bottom: -50px;
        right: calc(100% - 43px);
        z-index: 2;
        width: 120px;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      .shadow-right {
        position: absolute;
        top: -30px;
        z-index: 9;
        right: -49px;
      }

      .single {
        padding-top: calc(400 / 370 * 100%);
      }
    }

    &__thumb {
      width: 30%;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 15px;
      height: fit-content;
      z-index: 3;

      &__inner {
        .single {
          padding-top: 100%;
          background-color: #EEE;
        }
      }
    }
  }

  .amenities__right {
    //padding-top: 50px;
    position: relative;

    p {
      font-size: 16px;
      line-height: 24px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      white-space: normal;
      margin-bottom: 54px;
      font-family: "Suisse Int'l";
      @media (max-width: 767px) {
       margin-top: 40px;
      }
      @media (max-width: 1024px) {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
    }
    
    h4{
      font-size: 24px;
      line-height: 32px;
      font-family: Kudryashev Display;
      color: #2D2D2D;
      
    }
    h6{
      font-size: 16px;
      line-height: 24px;
      font-family: Kudryashev Display;
      color: #2D2D2D;
      margin-bottom: 10px;
    }

  }

  .slider-nav {
    position: absolute;
    //bottom: 50px;
    width: 40%;
    right: 0;
    bottom: 0;
    z-index: 3;
    //padding-left: 20px;
    ul {
      display: flex;
      @media(max-width: 767px){
        margin-left: 10px;
      }
    }

    li {
      height: 40px;
      width: 40px;
      //background-color: rgb(34, 31, 31);
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      border: 1px solid black;

      &:nth-of-type(2) {
        //padding: 0 20px;
        margin: 0 20px;
      }

      //&:not(:nth-of-type(2)) {
      //  cursor: pointer;
      //}

      svg {
        z-index: 2;
      }

      &:hover{
        border: 1px solid #AC8B7C;
      }

    }
    p {
      position: absolute;
      font-size: 36px;
      line-height: 40px;
      color: #AC8B7C;
      bottom: -10px;
      right: 15px;

      span {
        font-size: 20px;
        color: #AC8B7C;
        opacity: .5;
      }
    }
    @media(max-width: 767px){
      margin-right: 5px;
    }
  }

  @media (max-width: 950px) {
    .col-sm-5, .col-sm-4 {
      min-width: 100%;
      //padding: 0 30px;
    }

    .amenities__big__thumb {
      width: 45%;
      top: auto;
      bottom: -200px;
    }

    .amenities__right {
      margin-top: 250px;
      margin-bottom: 100px;

      p {
        display: block;
        overflow: visible;
      }
    }

    .amenities__big__slider .shadow {
      z-index: 1;
      width: 120px;
      right: 0;
      left: calc(23% + 180px);
    }

    .slider-nav {
      bottom: 0 !important;
      width: 98% !important;

    }

  }

  @media (max-width: 767px) {
    margin-top: 100px;
    .container {
      //padding: 0;
    }

    .amenities__big__thumb {
      height: 150px;
      width: 55%;
      bottom: -75px;
      left: 30px;

      div {
        height: 100%;
        width: 100%;
      }

      .single {
        padding: 0 !important;
      }
    }

    .amenities__big__slider  {
      .shadow-right {
     
        right: 0;
      }
      
      
      
    }

    .amenities__right {
      margin-top: 0px;
    }
  }
  
  .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }
`;

export default MyComponent;
