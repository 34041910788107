import React, {useState} from 'react';
import styled from "styled-components";
import ModalVideo from "react-modal-video";
import ReactHtmlParser from "react-html-parser";
import moment from 'moment';
import {Img} from "./Img";

const ProjectList = ({element}) => {

    return (
        <StyledProjectList>

                    <div >
                        <div className='project fade-up'>
                            <div className='project__img '>
                                <Img src={element?.detail_info?.thumb? element?.detail_info?.thumb : '/images/dynamic/home/video.jpg'}></Img>
                                <div className='project__img__content'>
                                    <div className="project__img__content__upper">
                                        <p>{element?.detail_info?.date}</p>
                                        {/*<p >(15)</p>*/}
                                    </div>

                                    <h6 >{ReactHtmlParser(element?.post_title)}</h6>
                                </div>
                                <div className="video__box__inner__click">
                                    <div className="svg-container">
                                        <svg id="Group_20003" data-name="Group 20003" xmlns="http://www.w3.org/2000/svg"
                                             width="140" height="140" viewBox="0 0 140 140">
                                            <g id="Ellipse_10" data-name="Ellipse 10" transform="translate(140 140) rotate(180)"
                                               fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="1">
                                                <circle id="circle-border" cx="70" cy="70" r="70" stroke="#FFFDFB" stroke-width="1"/>
                                                <circle id="hover-color" cx="70" cy="70" r="69.5" stroke="none" fill=""/>
                                            </g>
                                            <path id="Polygon_1" data-name="Polygon 1" d="M15,0,30,20H0Z"
                                                  transform="translate(83 55) rotate(90)" fill="#fffdfb"/>
                                        </svg>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
        </StyledProjectList>
    );
};
const StyledProjectList = styled.section`
  background-color: #FFFFFF;
  position: relative;
  overflow: hidden;

  .overflow {
    overflow: hidden;
  }
  

  //project 
  .project {
    &__img {
      position: relative;
      padding-top: calc(550 / 570 * 100%);
      //overflow: hidden;
      z-index: 4;
      cursor: pointer;

      a.wrapped {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        /* background: red; */
        z-index: 5;
      }

      &:after {
        width: 100%;
        height: 100%;
        position: absolute !important;
        top: 0px;
        content: '';
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      }


      &__content {
        &__upper{
          display: flex;
          width: 100%;
          justify-content: space-between;
          p{
            font-size: 16px;
            line-height: 24px;
            font-family: "Suisse Int'l";
            font-weight: 400;
            color: #F9F8F0;
          }
        }
        position: absolute;
        bottom: 35px;
        left: 40px;
        right: 40px;
        z-index: 1;
        @media (max-width: 992px) {
          font-size: 18px;
          line-height: 27px;
        };

        h6 {
          color: #FFFEFB;
          opacity: 1;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          font-family: Kudryashev Display;
          
        }

        

        // &__bottom {
        //   opacity: 1;
        // }
      }


    }
  }

  .video__box__inner__click {
    //height: 80px;
    //width: 80px;
    //border-radius: 50%;
    //background-color: #8F6D4F;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    .svg-container {
      display: inline-block;
      position: relative;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      svg{
        width: 100%;
        height: 100%;
      }
    }

    #hover-color {
      fill: transparent;
      transition: fill 0.8s;
    }
    #circle-border{
      transition: all 1s;
    }


    .svg-container:hover {

      #hover-color {
        fill: #AC8B7C;
        animation: fillAnimation 0.8s forwards;
      }
      #circle-border{
        stroke-width: 0;
      }
    }


    .svg-container:not(:hover) #hover-color {
      fill: transparent;
      animation: reverseFillAnimation 0.8s backwards;
    }

    @keyframes fillAnimation {
      0% {
        r: 0px;
      }
      100% {
        r: 70px;
      }
    }
    @keyframes reverseFillAnimation {
      0% {
        r: 69.5px;
      }
      100% {
        r: 0px;
      }
    }
    
  }


  @media (max-width: 768px) {
    .project__img__content__bottom {
      display: none;

    }

    .project__img__content {
      transform: none;
      bottom: 26px;

    }
  }

`

export default ProjectList;