import React, {useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Button from "../Button";
import {
    LightgalleryProvider,
    LightgalleryItem,
} from "react-lightgallery";
import ModalVideo from "react-modal-video";
import Media from "./Media";
import PopupV1 from "../PopUpModal";

const ProjectList = ({padding, title,data}) => {

    const [isCompleted, setIsCompleted] = useState(false)
    const [index, setIndex] = useState( 6)

    const loadMore = () => {

        setIndex(index + 6)
        //setClear(false)

        if (index >= data?.length) {
            setIsCompleted(true)
            document.querySelector('.laodmorehide').classList.add('hide');
        } else {
            setIsCompleted(false)
        }
    }
    const PhotoItem = ({image, thumb, group, data}) => (
        <LightgalleryItem group={group} src={image} thumb={thumb}>
            <Media img={image} data={data} photoGallery/>
        </LightgalleryItem>

    );
    const PhotoItem2 = ({image, thumb, group}) => (
        <div style={{maxWidth: "250px", width: "200px", padding: "5px"}}>
            <LightgalleryItem group={group} src={image} thumb={thumb}>
                <img src={image} style={{width: "100%"}}/>
            </LightgalleryItem>
        </div>
    );

//////////////////

    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()
    const [showVideo, setShowVideo] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
        setVideo(true)
    }

    return (
        <StyledProjectList className={`project-listing`}>
            <Container>
                <Row>
                    {
                        data?.length > 0 && data?.slice(0, index)?.map((element) => (
                            element?.detail_info?.video_id ?
                                <>
                                    <PopupV1 show={show}  video={true} direct_video={element?.detail_info?.video_id} handleClose={handleClose}/>
                                    <Col lg={6} onClick={() => handleShow(true)} key={element?.ID}>
                                        <Media img={element?.detail_info?.thumb} data={element}/>
                                    </Col>
                                </> :
                                <LightgalleryProvider
                                    // group={`group${img?.ID}`}
                                    lightgallerySettings={
                                        {
                                            download: false,
                                            // thumbnail: false,
                                            fullScreen: true,
                                            share: false
                                        }
                                    }
                                >

                                    <Col lg={6} key={element?.ID}>
                                        <PhotoItem key={element.ID} image={element?.detail_info?.list?.[0]?.image}
                                                   group={`group${element?.detail_info?.list?.[0]?.ID}`} data={element}/>
                                        <div
                                            style={{
                                                display: "none",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            {element?.detail_info?.list ? element?.detail_info?.list?.slice(1)?.map((element) => (
                                                <PhotoItem2 key={element.ID} image={element?.image}
                                                            group={`group${element?.ID}`} />
                                            )) : ''}
                                        </div>
                                    </Col>

                                </LightgalleryProvider>

                        ))
                    }
                </Row>
                <Col md={12} className={'text-center'}>
                    {data?.length<= 6 ? '': (
                        <div className={'laodmorehide'} onClick={loadMore}>
                            <Button margin={'20px 0 0'} fontSize={'16'} letterSpacing={'0'} color={'#F0EDE3'} background={'#1C1718'}  text={'View More'}/>

                        </div>
                    )}

                </Col>

            </Container>

        </StyledProjectList>
    );
};

const StyledProjectList = styled.section`
  position: relative;
  padding-top: 100px;
  padding-bottom: 140px;
  .col-lg-6 {
    margin-bottom: 60px;
  }

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    top: -100px;
    height: 100px;
    background: #004195;
    content: '';
    z-index: -1;
  }


  .project-list-wrap {
    &__single{
      margin-bottom: 60px;
    }
    //background: #f9f9f9;
  }

  .load-more {
    margin-top: 30px;
    .dc-btn{
      display: flex;
      justify-content: center;
    }
  
  }

  @media (min-width: 1550px) {
    .project-listing {
      &__filter {
        label {
          font-size: 16px;
          line-height: 20px;
        }


        .react-select__single-value {
          font-size: 16px;
        }
      }
    }


  }
  @media (max-width: 767px) {
    padding-top: 120px;
    //.reveal {
    //  border-bottom-right-radius: 30px !important;
    //}
    .project-list-wrap {
      &__single{
        margin-bottom: 40px;
      }
      //background: #f9f9f9;
    }

    .project-listing {
      &__filter {
        padding: 40px 0;
        border-bottom-right-radius: 30px;

        &__single {
          margin-bottom: 15px;

          &:last-child {
            margin: 0;
          }
        }

        .react-select__menu {
          padding: 0;
          border-radius: 0;

          ul {
            margin: 0;
            padding: 0;
          }
        }

        .react-select__control {
          border: 1px solid #FFFFFF;
          height: 50px;

          &:hover {
            border: 1px solid #FFFFFF;
          }

          &:focus {
            border: 1px solid #FFFFFF;

          }
        }

        .react-select__indicator-separator {
          display: none;
        }

        .react-select__single-value {
          color: #FFFFFF;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

  }
`;

export default ProjectList;
