import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Select, {components} from "react-select";
import {hover} from '../../styles/globalStyleVars';
import ProjectBox from "../ProjectBox";
import Button from "../Button";
import {useHistory, useLocation} from "react-router-dom";
import {ApiParam} from "../../api/network/apiParams";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchProject} from "../../api/redux/project";
import {useDispatch} from "react-redux";

const ProjectList = ({padding, title,data,option}) => {


    const dispath = useDispatch();
    const selectTypeInputRef = useRef();
    const selectLocationInputRef = useRef();


    const [isCompleted, setIsCompleted] = useState(false)
    const [index, setIndex] = useState(6)
    const [nodata, setNoData] = useState('')
    const initialData = data?.slice( 0, index);



    //Load More
    const handleLoadMore = () => {
        setIndex(index + 6)
        if (index >= data?.length) {
            setIsCompleted(true)
        } else {
            setIsCompleted(false)
        }
    }



    //filters dynamic options
    let project_location=option?.project_location;
    let project_status=option?.project_status;
    let project_type=option?.project_type;



    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)",
        }),
        control: (base, state) => ({
            ...base,
            backgroundColor: 'transparent',
            borderColor: "#B8B8B8",
            borderRadius: 0,
            height: 60,
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                borderColor: "#222222",
                cursor: 'pointer'
            },
        }),
        option: (styles, state) => ({
            ...styles,
            backgroundColor: state.isSelected ? hover : '#FFF',
            "&:hover": {
                backgroundColor: "transparent",
                color: hover,
                cursor: 'pointer'
            },
            zIndex : '99999',
            position: 'relative',
        }),
        indicatorContainer: (base, state) => ({
            ...base,
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        }),
        menu: (base) => ({
            ...base,
            zIndex: 99999, // Increase the z-index value as needed
        }),

    };

    const history = useHistory();



    const handleStatus = (e) => {
        setSelectStatus(e?.value);
        history.push(`/project?status=${e?.value}`);

        // Router.push(`/project?status=${e?.value}`);
        selectTypeInputRef?.current?.select?.clearValue();
        selectLocationInputRef?.current?.select?.clearValue();
    }

    const handleType = (e) => {
        setSelectType(e?.value);
        selectLocationInputRef?.current?.select?.clearValue();

    }
    const handleLocation = (e) => {
        setSelectLocation(e?.value);
    }

    // get the query
    const locations = useLocation();
    const status = new URLSearchParams(locations?.search).get("status");


    // step -01

    const [selectStatus, setSelectStatus] = useState('')
    const [selectType, setSelectType] = useState('')
    const [selectLocation, setSelectLocation] = useState('')

    // step -02

    // api call

    useEffect(() => {

        if(status===project_status){
            let param = {
                [ApiParam.project_status]: project_status,
                [ApiParam.project_type]: selectType,
                [ApiParam.project_location]: selectLocation
            }
            let api_services = apiEndPoints.PROJECT
            dispath(fetchProject([api_services,param]))
        }
        else{
            let param = {
                [ApiParam.project_status]: status? status : project_status,
                [ApiParam.project_type]: selectType,
                [ApiParam.project_location]: selectLocation,
            }
            let api_services = apiEndPoints.PROJECT
            dispath(fetchProject([api_services,param]))
        }

    }, [selectStatus, selectType, selectLocation,status])

    const CaretDownIcon = () => {
        return <svg width="17.414" height="9.207" viewBox="0 0 17.414 9.207">
            <path id="Path_1413" data-name="Path 1413" d="M18,7.5l8,8-8,8"
                  transform="translate(24.207 -17.293) rotate(90)" fill="none" stroke="#2D2D2D" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="1"/>
        </svg>
            ;
    };
    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };
    return (
        <StyledProjectList className={`project-listing pb-200`}>
            <div className="project-listing__filter">
                <Container>
                    <Row>
                        <Col className="project-listing__filter__single" md={4}>
                            <Select
                                    styles={customStyles}
                                    onChange={handleStatus}
                                    options={project_status}
                                    classNamePrefix={'react-select'}
                                    components={{DropdownIndicator}}
                                    defaultValue={{label: status ? status : 'Select Status', value: status ? status : 'Select Status'} }


                            />
                        </Col>
                        <Col className="project-listing__filter__single" md={4}>
                            <Select styles={customStyles}
                                    onChange={handleType}
                                    ref={selectTypeInputRef}
                                    options={project_type}
                                    classNamePrefix={'react-select'}
                                    components={{DropdownIndicator}}
                                    defaultValue={{label: 'Select Type', value: 'Select Type'}}

                            />
                        </Col>
                        <Col className="project-listing__filter__single" md={4}>
                            <Select styles={customStyles}
                                    onChange={handleLocation}
                                    options={project_location}
                                    classNamePrefix={'react-select'}
                                    components={{DropdownIndicator}}
                                    defaultValue={{label: 'Select Location', value: 'Select Location'}}

                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="project-list-wrap pt-120 pb-120">
                <Container>
                    <Row>
                        {
                            initialData?
                            initialData && initialData.length>0 &&
                            initialData.map((element,index)=>{

                                return(
                                    <Col md={4} className={'project-list-wrap__single '} key={index}>
                                        <ProjectBox img={element?.project_thumb ? element?.project_thumb : element?.image }
                                                    src={`project/${element?.post_name}`}
                                                    title={element?.post_title}
                                                    type={element?.project_type}
                                                    location={element?.project_location}/>
                                    </Col>
                                )
                            }) : <Col><p>No data is found</p></Col>
                        }
                        {
                            index >= data?.length || initialData===undefined ? "" :
                                <Col md={12} className={'load-more'} onClick={handleLoadMore}>
                                    <Button text={'Load More'}/>
                                </Col>
                        }


                    </Row>
                </Container>
            </div>
        </StyledProjectList>
    );
};

const StyledProjectList = styled.section`
  position: relative;
  padding-top: 100px;
  

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    top: -100px;
    height: 100px;
    background: #004195;
    content: '';
    z-index: -1;
  }
.react-select__placeholder{
  text-transform: capitalize;
}
  .project-listing {
    &__filter {
      z-index: 9999;
      padding: 0 0 60px 0;

      .react-select__menu {
        padding: 0;
        border-radius: 0;

        ul {
          margin: 0;
          padding: 0;
        }
      }

      .react-select__control {
        border: 1px solid #2D2D2D;
        height: 45px;

        &:hover {
          border: 1px solid #2D2D2D;
        }

        &:focus {
          border: 1px solid #2D2D2D;

        }
      }
      

      .react-select__indicator-separator {
        display: none;
      }

      .react-select__single-value {
        color: #2D2D2D;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: "Suisse Int'l";
      }
    }
  }

  .project-list-wrap {
    //background: #f9f9f9;
  }

  .load-more {
    margin-top: 30px;
    .dc-btn{
      display: flex;
      justify-content: center;
    }
  
  }

  @media (min-width: 1550px) {
    .project-listing {
      &__filter {
        label {
          font-size: 16px;
          line-height: 20px;
        }


        .react-select__single-value {
          font-size: 16px;
        }
      }
    }


  }
  @media (max-width: 767px) {
    //.reveal {
    //  border-bottom-right-radius: 30px !important;
    //}
    padding-top: 0px;

    .project-listing {
      &__filter {
        padding: 40px 0;
        border-bottom-right-radius: 30px;

        &__single {
          margin-bottom: 15px;

          &:last-child {
            margin: 0;
          }
        }

        .react-select__menu {
          padding: 0;
          border-radius: 0;

          ul {
            margin: 0;
            padding: 0;
          }
        }

        .react-select__control {
          border: 1px solid #2D2D2D;
          height: 50px;

          &:hover {
            border: 1px solid #FFFFFF;
          }

          &:focus {
            border: 1px solid #FFFFFF;

          }
        }

        .react-select__indicator-separator {
          display: none;
        }

        .react-select__single-value {
          color: #2D2D2D;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

  }
`;

export default ProjectList;
