import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {fetchHome, fetchPosts} from "../../api/redux/home";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import {Link} from "react-router-dom";
import Button from "../../components/Button";
import {Img} from "../../components/Img";
import Title from "../../components/Title";
import InnerBanner from "../../components/InnerBanner";
import {Loading} from "../../components/Loading";
import {Helmet, HelmetProvider} from "react-helmet-async";
import Overview from "../../components/Overview";
import Banner from "../../components/home/Banner";
import ProjectSlider from "../../components/home/ProjectSlider";
import Video from "../../components/home/Video";
import ProjectVideoMerge from "../../components/ProjectVideoMerge";
import Concern from "../../components/home/Concern";
import TestimonialSlider from "../../components/home/TestimonialSlider";
import VideoPhotoSlider from "../../components/home/VideoPhotoSlider";
import VideoModal from "../../components/VideoModal";
import MediaGallery from "../../components/home/MediaGallery";


const Home = () => {

    const dispath = useDispatch();
    let getPost = useSelector(state => state.home)

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.HOME
        dispath(fetchHome([api_url]))
    }, [])


    //Data  Refactor
    let banner = getPost?.data?.page_data?.banner;
    let interior = getPost?.data?.page_data?.interior;
    let about = getPost?.data?.page_data?.about_section;
    let feature_project = getPost?.data?.page_data?.feature_project;
    let testimonial = getPost?.data?.page_data?.testimonial;
    let video = getPost?.data?.page_data?.video;

    let featuredProjects=getPost?.data?.project_list;
    let medias=getPost?.data?.media_list;
    let featuredMedia=medias?.filter(element=>element?.detail_info?.isfeatured=='on');

    return (


        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Kopotakkho Homes Ltd.</title>
                <meta name="description"
                      content="Kopotakkho Homes Ltd, trailblazers in the Real Estate Industry of Bangladesh, redefining exceptional living within
affordable boundaries since 2005. We have epitomized ‘The Sign of Excellence’, delivering flawless designs that
astound the sights to see."/>
            </Helmet>

            <StyledSection>
                <Banner data={banner}/>
                {about?.subtitle && about?.description &&
                    <Overview title={about?.subtitle}
                              text={about?.description}
                              leaf
                              button={'About KHL'}
                              link={'/about'}
                    />
                }
                {
                    video?.bg_image && feature_project?.title && featuredProjects?.length>0 &&
                        <ProjectVideoMerge video={video} title={feature_project?.title} data={featuredProjects}/>

                }
                {
                    interior?.image && interior?.description &&
                    <Concern data={interior}/>

                }
                <TestimonialSlider data={testimonial}/>
                <MediaGallery data={featuredMedia}/>

            </StyledSection>
        </HelmetProvider>

    );
};

const StyledSection = styled.section`

  h3 {
    font-family: "Suisse Int'l";
  }

`;

export default Home;
