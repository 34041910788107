import React, {useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "./Img";

import {
    LightgalleryProvider,
    LightgalleryItem,
} from "react-lightgallery";
import ModalVideo from "react-modal-video";
import ReactHtmlParser from "react-html-parser";
import moment from 'moment';

const ProjectList = ({data}) => {


    let handelImage = (image) => {
        // setImage(image);
    };
    const PhotoItem = ({image, thumb, group, data}) => (
        <LightgalleryItem group={group} src={image} thumb={thumb}>
            <div className='project fade-up'>
                <div className='project__img reveal'>
                    <Img src={image}/>
                    <div className='project__img__content'>
                        <div className="project__img__content__upper">
                            <p className={'split-up'}>Nov 28, 2022</p>
                            <p className={'split-up'}>(15)</p>
                        </div>

                        <h6 className={'split-up'}>{ReactHtmlParser('Latest Interior trends')}</h6>
                    </div>

                </div>
            </div>

        </LightgalleryItem>

    );

    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
    };

    return (
        <StyledProjectList>

            <LightgalleryProvider
                lightgallerySettings={
                    {
                        download: false,
                        // thumbnail: false,
                        fullScreen: true,
                        share: false
                    }
                }
            >
                {/*<Col lg={12} key={0}>*/}
                    <PhotoItem  image={'/images/dynamic/home/photo.jpg'} group={'1'} data={''}/>
                {/*</Col>*/}
            </LightgalleryProvider>

        </StyledProjectList>
    );
};
const StyledProjectList = styled.section`
  background-color: #FFFFFF;
  position: relative;
  //margin-bottom: -60px;
  overflow: hidden;

  .overflow {
    overflow: hidden;
  }


  //project 
  .project {
    &__img {
      position: relative;
      padding-top: calc(550 / 570 * 100%);
      //overflow: hidden;
      z-index: 4;
      cursor: pointer;

      a.wrapped {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        /* background: red; */
        z-index: 5;
      }

      &:after {
        width: 100%;
        height: 100%;
        position: absolute !important;
        top: 0px;
        content: '';
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      }


      &__content {
        position: absolute;
        bottom: 35px;
        left: 40px;
        right: 40px;
        z-index: 1;
        //transform: translateY(calc(100% - 30px));
        @media (max-width: 992px) {
          font-size: 18px;
          line-height: 27px;
        };
        &__upper{
          display: flex;
          width: 100%;
          justify-content: space-between;
          p{
            font-size: 16px;
            line-height: 24px;
            font-family: "Suisse Int'l";
            font-weight: 400;
            color: #F9F8F0;
          }
        }

        h6 {
          color: #FFFEFB;
          opacity: 1;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          font-family: Kudryashev Display;

        }
        
        // &__bottom {
        //   opacity: 1;
        // }
      }


    }
  }

  .video__box__inner__click {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: #8F6D4F;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    &:after {
      content: '';
      position: absolute;
      height: 80px;
      width: 80px;
      border-radius: 100%;
    }


    svg {
      position: relative;
      z-index: 2;
      transition: all .3s ease;
      transform: none;
    }

    p {
      font-size: 12px;
      font-weight: bold;
      line-height: 12px;
      color: #f2eee8;
      text-transform: uppercase;
      text-align: center;
      opacity: 0;
      position: absolute;
      transition: all .3s ease;
      transform: translateY(10px);
    }

    &.hover {
      &:after {
        background-color: #8F6D4F;
        border-radius: 50%;
      }
    }

    &:hover {
      &:after {
        animation: big 2.5s ease infinite;
      }

      svg {
        opacity: 0;
        transform: translateY(-10px);
      }

      p {
        opacity: 1;
        transform: none;
      }
    }

  }


  @media (max-width: 768px) {
    .project__img__content__bottom {
      display: none;

    }

    .project__img__content {
      transform: none;
      bottom: 26px;

    }
  }

`

export default ProjectList;