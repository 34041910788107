import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Form, Row} from "react-bootstrap";
import Title from "../Title";
// import {emailValidation, emptyNumber, emptyValidation} from "../pages/api/config/validator";
import {useDispatch, useSelector} from "react-redux";
// import {ApiServices} from "../pages/api/network/ApiServices";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Button from "../Button";
import {useForm} from "react-hook-form";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/career";
import Img from "../Img";


const ContactForm = ({padding, background, border_color, text_color,getPost,title,img}) => {

    const dispatch = useDispatch();
    const responseData = useSelector(state => state.career);

    const {register, handleSubmit ,formState,reset} = useForm({mode: 'all'});


    const [cv, setCv] = useState(null)


    // disable scroll on input
    useEffect(() => {
        var inputTypeNumbers = document.querySelectorAll("input[type=number]");
        for (var a = 0; a < inputTypeNumbers.length; a++) {
            inputTypeNumbers[a].onwheel = function (event) {
                event.target.blur();
            };
        }
    }, [])

    const [selectedFileName, setSelectedFileName] = useState('');

    const handleFileChange = (event) => {
        setCv(event.target.files[0])
        setSelectedFileName(event.target.files?.[0]?.name);

    };
    // const handleInputcv = (e) => {
    //     setCv(e.target.files[0])
    //     setSelectedFileName(e.target.files?.[0]?.name);
    //
    // }

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const onSubmit = (e) => {

        let api_services = apiEndPoints.CAREER_FORM;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('your-phone', e?.phone);
        formData.append('your-message', e?.message);
        formData.append('file-795', cv);
        if (e.email !== '' && e.name !== '' && e.phone !== '' && cv!== '') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
            setSelectedFileName('')
        }

    };
    let count=0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            // success(responseData?.success)
        }

    }, [responseData])

    return (

        <StyledContactForm background={background} border_color={border_color} text_color={text_color}
                           className={`contact_form ${padding ? padding : 'pb-200'}`}>

            {/*{responseData?.loading && <Loader/>}*/}

            <Container>
                <Row>
                    <Col md={12}>
                        <Title margin={'0 0 60px'} text={title} />
                    </Col>

                    <Col md={5}>
                        <div className="contact-img">
                            <Img src={img? img : '/images/dynamic/contact/form.jpg'}  />

                        </div>
                    </Col>

                    <Col md={{span: 5, offset: 1}}>
                        {/*<Form onSubmit={handleSubmit}>*/}
                        <Form>
                            <Row className={''}>
                                <div className="form_wrapper">
                                    <Form.Group className="col-md-3 single pb-30">
                                        <Form.Control
                                            className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                            {...register("name",{
                                                required: 'Username is required',
                                                pattern: {
                                                    value: /^[A-Za-z]+$/,
                                                    message: 'Username must contain only letters',
                                                },

                                            })}
                                            type="text"
                                            placeholder="Your Name *"/>
                                    </Form.Group>
                                    <Form.Group className="col-md-3 single pb-30">
                                        <Form.Control
                                            className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                            {...register("email",{
                                                required:{
                                                    value:true,
                                                    message:'please enter your email'
                                                },
                                                pattern:{
                                                    value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message:'please enter a valid email address'
                                                }
                                            })}
                                            type="email"
                                            placeholder="Your Email *"/>
                                    </Form.Group>
                                    <Form.Group className="col-md-3 single pb-30">
                                        <Form.Control
                                            className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                            {...register("phone",{
                                                required:{
                                                    value:true,
                                                    message:'please enter your phone first'
                                                },
                                                pattern:{
                                                    value:/^01[0-9]{9}$/,
                                                    message:'please enter a valid 11 digit phone number'
                                                }
                                            })}
                                            type="number"
                                            placeholder="Your Number *"/>
                                    </Form.Group>
                                    <Form.Group className="col-md-6 single half pb-30">
                                        <Form.Control
                                            className={formState?.errors?.message?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                            {...register("message",{
                                                required:{
                                                    // value:true,
                                                    message:'please enter your message'
                                                },

                                            })}
                                            rows={3} placeholder="Your Message *"/>
                                    </Form.Group>
                                    <div className={'button-group'}>
                                        <div className="attachCvName">
                                            <div className="attach-cv">

                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style={{ display: selectedFileName ? "none" : "block" }}>
                                                    <g id="Group_20154" data-name="Group 20154" transform="translate(-133.895 -717.5)">
                                                        <path id="Path_1748" data-name="Path 1748" d="M744.85,1223.491l-3.321,3.321a3.1,3.1,0,0,0,0,4.384h0a3.1,3.1,0,0,0,4.384,0l3.321-3.321" transform="translate(-606.226 -499.105)" fill="none" stroke="#2d2d2d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                        <path id="Path_1749" data-name="Path 1749" d="M760.654,1216.6l3.222-3.222a3.1,3.1,0,0,0,0-4.384h0a3.1,3.1,0,0,0-4.384,0l-3.222,3.222" transform="translate(-615.389 -490.081)" fill="none" stroke="#2d2d2d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                        <line id="Line_67" data-name="Line 67" y1="3.818" x2="3.818" transform="translate(139.986 723.591)" fill="none" stroke="#2d2d2d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                    </g>
                                                </svg>

                                                <Form.Control
                                                    type="file"
                                                    accept=".pdf"
                                                    {...register('file')}
                                                    id="resume-upload"
                                                    style={{ display: "none" }}
                                                    onChange={handleFileChange}
                                                />
                                                <Form.Label htmlFor="resume-upload" className="resume-upload-label" style={{ display: selectedFileName ? "none" : "block" }}>
                                                    Attach Resume
                                                </Form.Label>
                                                {selectedFileName && (
                                                    <div className="file-name">
                                                        {selectedFileName}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-12 underline  p-0" onClick={handleSubmit(onSubmit,onError)}
                                        >
                                            <Button  color={'#5C5550'} border={'#F7F3F0'} text={'Submit'}  background={'#F7F3F0'} fontWeight={'bold'} fontSize={'16px'} lineHeight={'24px'} height={'41'} />

                                        </div>

                                    </div>

                                </div>

                            </Row>
                        </Form>

                    </Col>
                </Row>
            </Container>
        </StyledContactForm>

    )
};


const StyledContactForm = styled.div`
  background: #F9F8F5;

  .button-group {
    display: flex;
    

    .attach-cv {
      width: 170px;
      cursor: pointer;
      border: 1px solid #2D2D2D;
      border-radius: 28px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-top: 60px;
      margin-right: 60px;
      
      label{
        margin-bottom: 0;
        cursor: pointer;
      }
      .file-name{
        font-size: 14px;
        opacity: 0.8;
        color: black;
        padding-left: 10px;
      }
      @media(max-width: 767px){
        width: 100%;

      }
    }
    @media(max-width: 767px){
      flex-direction: column;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  
  .contact-img{
    position: relative;
    padding-top: calc(470 / 470 * 100% );
  }
  
  .fade-up {
    transform: none !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
  
  .underline{
    margin-top: 60px;
  }

  .underline-btn {
    &:before {
      background: #010A1A;
      transition: all 0.3s ease;
    }

    &:after {
      display: none;
    }

    &:hover {
      &:before {
        height: 4px;
      }
    }
  }
  

  .btn-primary {
    color: #010A1A !important;
  }

  @media (max-width: 767px){
   .dc-btn a{
      width: 100% !important;
    }
    .contact-img{
      margin-bottom: 40px;
    }
    .underline{
      margin-top: 15px;
    }
    
  }
  
  
  @media (max-width: 992px) and (min-width: 767px) {
    
   
    
    
    .form_wrapper {
      .single {
        margin-right: 30px;

      }
    }
  }
`;


export default ContactForm;