import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchPosts} from "../../api/redux/post";
import InnerBanner from "../../components/InnerBanner";
import Map from "../../components/contact/Map";
import ContactOverview from "../../components/contact/ContactOverview";
import ContactForm from "../../components/contact/ContactForm";
import {fetchContact} from "../../api/redux/contact";
import Overview from "../../components/Overview";
import CareerForm from "../../components/career/CareerForm";
import {fetchCareer} from "../../api/redux/career";
const MyComponent = () => {
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CAREER
        dispath(fetchCareer([api_url]))
    }, [])

    let getPost = useSelector(state => state.career);

    //Data Refactor

    let banner=getPost?.data?.[0]?.banner;
    let address=getPost?.data?.[0]?.overview;
    let form=getPost?.data?.[0]?.form;



    return (

        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Career | Kopotakkho Homes Ltd.</title>
                <meta name="description" content="Join the dynamic team at Kopotakkho Homes Ltd, an organization that recognizes your contributions, nurtures
your professional growth, and empowers you to create change. At KHL, we celebrate a culture anchored in
diversity, inclusivity, and lifelong learning. "/>
            </Helmet>

            <StyledComponent>
                <InnerBanner img={banner?.image} title={banner?.title}/>
                <Overview bg={'#F9F8F5'} title={address?.title} text={address?.description} paddingTop={'100px'}/>
                <CareerForm title={form?.title} img={form?.image}/>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
